import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { CustomerAppRouter } from "api";
import { geTtoken } from "../utils/localStorage";

// Create a type for the tRPC hooks
type TRPCHooks = ReturnType<typeof createTRPCReact<CustomerAppRouter>>;

// Explicitly type `trpc` using the TRPCHooks type
export const trpc: TRPCHooks = createTRPCReact<CustomerAppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn();
        await opts.queryClient.invalidateQueries();
      },
    },
  },
});

// Create a type for the tRPC client
type TRPCClient = ReturnType<typeof trpc.createClient>;

// Explicitly type `trpcClient` using the TRPCClient type
export const trpcClient: TRPCClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_BACKEND_BASEURL,
      headers() {
        return {
          Authorization: `Bearer ${geTtoken()}`,
        };
      },
    }),
  ],
});

// Export the types for use in other files if needed
export type { TRPCClient, TRPCHooks };

