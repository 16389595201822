import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpBatchLink, TRPCLink } from "@trpc/client";
import { observable } from "@trpc/server/observable";
import { CustomerAppRouter } from "api";
import { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./components/Common/isAuth";
import MainLoader from "./components/Common/loader/mainLoader";
import ErrorBoundary from "./components/hooks/errorBoundary";
import { ModalProvider, ModalProviders } from "./context/modalProvider";
import { trpc } from "./lib/trpc";
import AppRoutes from "./route";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [trpcClient] = useState(() => {
    const customLink: TRPCLink<CustomerAppRouter> = () => {
      // here we just got initialized in the app - this happens once per app
      return ({ next, op }) => {
        // this is when passing the result to the next link
        // each link needs to return an observable which propagates results
        return observable((observer) => {
          console.log("performing operation:", op);
          const unsubscribe = next(op).subscribe({
            next(value) {
              observer.next(value);
            },
            error(err) {
              observer.error(err);
              if (err?.data?.code === "UNAUTHORIZED") {
                logout();
                navigate("/login");
                queryClient.clear();
              }
              new QueryClient({
                defaultOptions: {
                  queries: {
                    refetchOnWindowFocus: false,
                  },
                },
              });
            },
            complete() {
              observer.complete();
            },
          });
          return unsubscribe;
        });
      };
    };

    return trpc.createClient({
      links: [
        customLink,
        httpBatchLink({
          url: import.meta.env.VITE_BACKEND_BASEURL,
          headers: () => {
            const token = localStorage.getItem("token");
            return {
              Authorization: token ? `Bearer ${token}` : undefined,
            };
          },
        }),
      ],
    });
  });

  const useLogOut = () => {
    clearTimeout(inactivityTimer); // Clear timer on logout
    document.removeEventListener("mousemove", useResetInactivityTimer);
    document.removeEventListener("keydown", useResetInactivityTimer);
    if (!toast.isActive("inactivityToast")) {
      toast.info("Logged out due to inactivity.", {
        toastId: "inactivityToast",
      });
    }
    logout();
    navigate("/login");
  };

  let inactivityTimer: string | number | NodeJS.Timeout | undefined;

  function useResetInactivityTimer() {
    clearTimeout(inactivityTimer);
    const timeoutDuration =
      import.meta.env.VITE_ENV === "development"
        ? 2 * 60 * 60 * 1000
        : 5 * 60 * 1000; // 2 hour for development, 5 minutes otherwise
    inactivityTimer = setTimeout(useLogOut, timeoutDuration);
  }

  // Add event listeners for user interactions
  document.addEventListener("mousemove", useResetInactivityTimer);
  document.addEventListener("keydown", useResetInactivityTimer);

  if (isAuthenticated) {
    useResetInactivityTimer();
  }

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        {new URLSearchParams(window.location.search).get("devtools") ===
          "true" && <ReactQueryDevtools initialIsOpen={false} />}
        <ModalProvider>
          <ModalProviders />
          <ErrorBoundary>
            <Suspense fallback={<MainLoader />}>
              <AppRoutes />
            </Suspense>
          </ErrorBoundary>
          <ToastContainer
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            style={{ fontSize: "14px", zIndex: 999999 }}
          />
        </ModalProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
