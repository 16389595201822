import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../components/Common/isAuth";
import MainLoader from "../../components/Common/loader/mainLoader";
import { isTokenValid } from "../../components/hooks/auth";

export const ProtectedRoute = ({ children }: { children?: JSX.Element }) => {

  if (isTokenValid()) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const UnprotectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === null) {
    return <MainLoader />; // Show a loading indicator while the state is being determined
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />; // Redirect authenticated users to the dashboard or another protected page
  }

  return children;
};

interface PublicRouteProps {
  children: ReactNode;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === null) {
    return <MainLoader />; // Show a loading indicator while the state is being determined
  }

  return <>{children}</>;
};


