/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorType?: string;
  route?: string;
  errorLocation?: string; // New field for line information
  componentStack?: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorType: undefined,
    route: undefined,
    errorLocation: undefined,
    componentStack: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Extract the error type and current route
    return {
      hasError: true,
      errorType: error.name,
      route: window.location.pathname,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);


    // Try to extract the line number from the stack trace (development-only info)
    const stackLines = error.stack?.split("\n");
    const errorLocation = stackLines
      ? stackLines[1]?.trim()
      : "Unknown location";

    // Update state with the line information
    this.setState({ errorLocation: errorLocation, componentStack: errorInfo.componentStack as string });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen p-8 text-white bg-slate-900">
          <div className="max-w-4xl mx-auto">
            <h1 className="mb-4 text-2xl font-bold text-red-400">
              Sorry..👋😤 you have committed {this.state.errorType}
              🤡😂❌🤡
            </h1>

            <div className="mb-6">
              <div className="mb-2 text-slate-300">
                <span className="font-semibold">Route: </span>
                <span className="px-2 py-1 font-mono rounded bg-slate-800">
                  {this.state.route}
                </span>
              </div>

              <div className="mb-2 text-slate-300">
                <span className="font-semibold">Error Type: </span>
                <span className="text-red-400">{this.state.errorType}</span>
              </div>

              <div className="text-slate-300">
                <span className="font-semibold">Location: </span>
                <span className="text-slate-400">
                  {this.state.errorLocation || "N/A"}
                </span>
              </div>
            </div>

            <div className="p-4 rounded-lg bg-slate-800">
              <h2 className="mb-2 text-lg font-semibold text-slate-300">
                Component Stack:
              </h2>
              <pre className="overflow-auto font-mono text-sm whitespace-pre-wrap text-slate-400">
                {this.state.componentStack}
              </pre>
            </div>

            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 mt-6 transition-colors rounded-lg bg-slate-700 hover:bg-slate-600"
            >
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
