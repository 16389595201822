import { FC } from "react";
import { loaderLogo } from "../svg";

const MainLoader: FC = () => {
  return (
    <div className="relative h-screen w-screen overflow-hidden bg-hgray-50">
      <div className="flex flex-col h-full w-full items-center justify-center overflow-hidden">
        <div className="relative mt-[-4rem] flex h-[95px] w-[95px] items-center justify-center overflow-hidden">
          <div className="h-full w-full overflow-hidden">
            <img
              className="circle"
              src="/loaderEllipse.png"
              alt="Loader icon"
            />
            <span
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
              dangerouslySetInnerHTML={{ __html: loaderLogo }}
            />
          </div>
        </div>
            <h4 className="mt-6 text-center text-[16px] font-normal text-htext-subdued">
              LOADING...
            </h4>
      </div>
    </div>
  );
};

export default MainLoader;
